import React from "react";
import { Helmet } from "react-helmet";

const UserAgreement = () => {
  const obfuscatedEmail = "info@wonderland.software";
  return (
    <div>
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <h1>User Agreement and Privacy Policy</h1>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By creating an account and using the Tap In app, you agree to be bound
        by this User Agreement and Privacy Policy. If you do not agree to these
        terms, please do not use our app.
      </p>

      <h2>2. Use of the App</h2>
      <p>
        Tap In is an app that allows you to create and share events. You are
        solely responsible for the events you create and share using our app.
        You agree to use the app only for lawful purposes and in compliance with
        all applicable laws and regulations.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        The Tap In app and all its content, features, and functionality are
        owned by Tap In and are protected by international copyright, trademark,
        patent, trade secret, and other intellectual property or proprietary
        rights laws.
      </p>

      <h2>4. Privacy Policy</h2>
      <h3>4.1 Information We Collect</h3>
      <p>
        When you create an account with Tap In using Sign in with Apple, we
        collect the following information:
      </p>
      <ul>
        <li>Your name</li>
        <li>Your email address</li>
        <li>A unique identifier provided by Apple</li>
      </ul>
      <p>
        When you create an event using Tap In, we collect the following
        information:
      </p>
      <ul>
        <li>Event title</li>
        <li>Event date and time</li>
        <li>Event location</li>
        <li>Any additional notes or details you provide about the event</li>
      </ul>

      <h3>4.2 How We Use Your Information</h3>
      <p>
        We use the information we collect to provide and improve our app's
        functionality, specifically:
      </p>
      <ul>
        <li>To create and manage your account</li>
        <li>To create and add events to your iCalendar</li>
        <li>To provide customer support and respond to your inquiries</li>
      </ul>

      <h3>4.3 Event Sharing</h3>
      <p>
        When you create an event using Tap In, you have the option to share the
        event link with others. It is your responsibility to decide with whom
        you want to share the event link. Keep in mind that anyone with access
        to the event link will be able to view the event details.
      </p>

      <h3>4.4 Information Sharing and Disclosure</h3>
      <p>
        We do not share, sell, or rent your personal information to third
        parties. The events you create using Tap In are stored securely and are
        not accessible to anyone other than you and those with whom you choose
        to share the event link.
      </p>

      <h3>4.5 Data Retention and Deletion</h3>
      <p>
        You have the option to delete your account and associated data within
        the Tap In app. When you choose to delete your account, all your
        personal information and events created using Tap In will be permanently
        deleted from our servers.
      </p>

      <h3>4.6 Security</h3>
      <p>
        We take reasonable measures to protect your information from
        unauthorized access, alteration, or destruction. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security.
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        In no event shall Tap In be liable for any indirect, incidental,
        special, consequential, or punitive damages arising out of or relating
        to your use of the app.
      </p>

      <h2>6. Termination</h2>
      <p>
        We may terminate or suspend your access to the app immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach this User Agreement.
      </p>

      <h2>7. Changes to This User Agreement</h2>
      <p>
        We may update our User Agreement from time to time. We will notify you
        of any changes by posting the new User Agreement on this page.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about our User Agreement, please
        contact us at{" "}
        <span
          style={{ unicodeBidi: "bidi-override", direction: "rtl" }}
          dangerouslySetInnerHTML={{
            __html: obfuscatedEmail
              .split("")
              .map((char) => `&#${char.charCodeAt(0)};`)
              .join(""),
          }}
        />
      </p>

      <p>This User Agreement was last updated on Thursday, June 6th, 2024.</p>
    </div>
  );
};

export default UserAgreement;
