import "./App.css";
import tapinLogo from "./Assets/tapin.svg";
import downloadApple from "./Assets/downloadApple.svg";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>tap in</title>
      </Helmet>
      <header className="App-header">
        <img src={tapinLogo} className="App-logo" alt="logo" />
        <a
          href="https://apps.apple.com/us/app/tap-in-social/id6503645889"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={downloadApple}
            className="Download-logo"
            alt="download apple"
          />
        </a>
      </header>
    </div>
  );
}

export default App;
