import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import "./EventPage.css"; // Import the CSS file for styling
import tapinLogo from "../Assets/tapin.svg";

const firebaseConfig = {
  apiKey: "AIzaSyDRJAiVjjBBHwVbaBqiboN5V0V20r0UV7g",
  authDomain: "tap-in-a8214.firebaseapp.com",
  projectId: "tap-in-a8214",
  storageBucket: "tap-in-a8214.appspot.com",
  messagingSenderId: "1048095297806",
  appId: "1:1048095297806:web:954684537b548a8730475f",
  measurementId: "G-CG7XTDX5DE",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const EventPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(db, "events", id));
        if (eventDoc.exists()) {
          setEvent(eventDoc.data());
        } else {
          setError("No such event found.");
        }
      } catch (err) {
        setError("Failed to fetch event data.");
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [id]);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(new Date(date.seconds * 1000));
  };

  const openInMaps = (location) => {
    const encodedLocation = encodeURIComponent(location);
    const url = `http://maps.apple.com/?q=${encodedLocation}`;
    window.open(url, "_blank");
  };

  const generateGoogleCalendarURL = (event) => {
    const startDate = new Date(event.startDate.seconds * 1000)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const endDate = new Date(event.endDate.seconds * 1000)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const details = `LOCATION:${event.location}\nDESCRIPTION:${event.notes}`;
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.title
    )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(details)}`;
  };

  const generateICSContent = (event) => {
    const startDate = new Date(event.startDate.seconds * 1000)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "")
      .replace(/:/g, "")
      .replace(/\..*$/, "");
    const endDate = new Date(event.endDate.seconds * 1000)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "")
      .replace(/:/g, "")
      .replace(/\..*$/, "");

    return `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
DTSTART:${startDate}
DTEND:${endDate}
SUMMARY:${event.title}
DESCRIPTION:${event.notes}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR
    `.trim();
  };

  const downloadICSFile = (event) => {
    const content = generateICSContent(event);
    const blob = new Blob([content], { type: "text/calendar" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${event.title}.ics`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="event-container">
      <Helmet>
        <title>{event.title} | tap in - event sharing</title>
        <meta
          property="og:title"
          content={`${event.title} | tap in - event sharing`}
        />
        <meta property="og:description" content="add this to your calendar!" />
        <meta
          property="og:image"
          content="https://tapin.lol/tapinlogobig.png"
        />
        <meta property="og:url" content={`https://tapin.lol/event/${id}`} />
        <meta property="og:type" content="website" />
        <meta name="fb:app_id" content="YOUR_FB_APP_ID" />
        <meta
          name="apple-itunes-app"
          content={`app-id=6503645889, app-argument=https://tapin.lol/event/${id}`}
        />
      </Helmet>

      <img src={tapinLogo} className="tap-in-logo" alt="tap in logo" />
      <div className="event-card">
        <div className="event-content">
          <h1 className="event-title">{event.title}</h1>
          {event.location && (
            <div
              className="event-location"
              onClick={() => openInMaps(event.location)}
            >
              <i className="fas fa-map-marker-alt"></i>
              <button className="location-button">{event.location}</button>
            </div>
          )}
          <div className="event-date">
            <i className="fas fa-calendar"></i>
            <span>Start: {formatDate(event.startDate)}</span>
          </div>
          <div className="event-date">
            <i className="fas fa-calendar"></i>
            <span>End: {formatDate(event.endDate)}</span>
          </div>
          {event.url && (
            <a
              className="event-url"
              href={event.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {event.url}
            </a>
          )}
          <div className="event-footer">
            <button
              className="add-to-calendar-button"
              onClick={() => downloadICSFile(event)}
            >
              Add to iCalendar
            </button>
            <a
              className="add-to-calendar-button"
              href={generateGoogleCalendarURL(event)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Add to Google Calendar
            </a>
          </div>
        </div>
      </div>
      {event.notes && (
        <div className="event-notes">
          <p>{event.notes}</p>
        </div>
      )}
    </div>
  );
};

export default EventPage;
